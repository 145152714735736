import { AiOutlineInstagram } from 'react-icons/ai';
import { TfiAngleRight } from 'react-icons/tfi';
import { TiSocialFacebook, TiSocialLinkedin } from 'react-icons/ti';
import { Version } from './Version';
import { createSubmission } from 'services/submissions';
import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Logo from 'public/TRHP-White-Transparent-Web-Logo.png';

export default function Footer() {
  const year = new Date().getFullYear();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [honey, setHoney] = useState('');
  const [subscribeMsg, setSubscribeMsg] = useState<string | false>(false);

  const subscribe = async (e: any) => {
    e.preventDefault();
    if (honey !== '') return;
    if (name !== '' && email !== '') {
      const saved = await createSubmission({
        formName: 'newsletter-subscribe',
        mailchimpList: '8eac5424a4',
        data: JSON.stringify({ name, email })
      });
      if (saved) {
        setSubscribeMsg('Thank you for subscribing!');
        setName('');
        setEmail('');
        setTimeout(() => {
          setSubscribeMsg(false);
        }, 5000);
      } else {
        setSubscribeMsg(
          'There was an error subscribing you. Please try again.'
        );
      }
    }
  };
  const navigation = {
    //need to change adoption pages back to /waiting-kids-[statecode]/
    adoption: [
      {
        name: 'MN Waiting Kids',
        href: '/waiting-kids/[statecode]/',
        as: '/waiting-kids/mn/'
      },
      {
        name: 'WI Waiting Kids',
        href: '/waiting-kids/[statecode]/',
        as: '/waiting-kids/wi/'
      },
      {
        name: 'ND Waiting Kids',
        href: '/waiting-kids/[statecode]/',
        as: '/waiting-kids/nd/'
      },
      {
        name: 'IA Waiting Kids',
        href: '/waiting-kids/[statecode]/',
        as: '/waiting-kids/ia/'
      },
      {
        name: 'WY Waiting Kids',
        href: '/waiting-kids/[statecode]/',
        as: '/waiting-kids/wy/'
      },
      {
        name: 'ID Waiting Kids',
        href: '/waiting-kids/[statecode]/',
        as: '/waiting-kids/id/'
      },
      {
        name: 'CO Waiting Kids',
        href: '/waiting-kids/[statecode]/',
        as: '/waiting-kids/co/'
      },
      {
        name: 'NV Waiting Kids',
        href: '/waiting-kids/[statecode]/',
        as: '/waiting-kids/nv/'
      },
      {
        name: 'Forever Family Follow-up Stories',
        href: '/forever-family-2/'
      }
    ],
    upstream: [
      {
        name: 'Prevent',
        href: '/upstream-stories-prevent/'
      },
      {
        name: 'Foster',
        href: '/upstream-stories-foster/'
      },
      {
        name: 'Wrap-Around',
        href: '/upstream-stories-wrap-around/'
      }
    ],
    socialworkers: [
      {
        name: 'How It Works',
        href: '/how-it-works/'
      },
      {
        name: 'Refer A Child',
        href: '/refer-a-child/'
      }
    ],
    about: [
      {
        name: 'About Us',
        href: '/about-us/'
      },
      {
        name: 'Meet Our Team',
        href: '/meet-our-team/'
      },
      {
        name: 'Join Our Team',
        href: '/join-our-team/'
      },
      {
        name: 'Media',
        href: '/media/'
      },
      {
        name: 'Contact Us',
        href: '/contact-us/'
      },
      {
        name: 'Privacy Policy',
        href: '/privacy-policy/'
      },
      {
        name: 'Annual Impact Reports',
        href: '/profit-and-loss-statement/'
      }
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/thereelhopeproject',
        icon: (props: any) => <TiSocialFacebook {...props} />
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/thereelhopeproject/',
        icon: (props: any) => <AiOutlineInstagram {...props} />
      },
      {
        name: 'Linkedin',
        href: 'https://www.linkedin.com/company/thereelhopeproject',
        icon: (props: any) => <TiSocialLinkedin {...props} />
      }
    ]
  };

  return (
    <footer className="bg-trhp-blue uppercase">
      <div className="mx-auto max-w-screen-2xl px-6 pb-8 pt-10 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-3 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-lg leading-6 text-white font-josefin underline font-normal">
                  Adoption
                </h3>
                <ul role="list" className="mt-2.5 space-y-1">
                  {navigation.adoption.map(item => (
                    <li key={item.name}>
                      <Link
                        {...(item.as && { as: item.as })}
                        href={item.href}
                        className="text-[0.813rem] font-thin leading-6 text-trhp-white hover:text-trhp-teal"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-lg leading-6 text-white font-josefin underline font-normal">
                  About
                </h3>
                <ul role="list" className="mt-2.5 space-y-1">
                  {navigation.about.map(item => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-[0.813rem] font-thin leading-6 text-trhp-white hover:text-trhp-teal"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                {/* <h3 className="text-lg leading-6 text-white font-josefin underline font-normal">
                  Classroom
                </h3>
                <ul role="list" className="mt-2.5 space-y-1">
                  {navigation.classroom.map(item => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-[0.813rem] font-thin leading-6 text-trhp-white hover:text-trhp-teal"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul> */}
                <h3 className="text-lg leading-6 mt-6 text-white font-josefin underline font-normal">
                  Social Workers
                </h3>
                <ul role="list" className="mt-2.5 space-y-1">
                  {navigation.socialworkers.map(item => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-[0.813rem] font-thin leading-6 text-trhp-white hover:text-trhp-teal"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-lg leading-6 text-white font-josefin underline font-normal">
                  Upstream
                </h3>
                <ul role="list" className="mt-2.5 space-y-1">
                  {navigation.upstream.map(item => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-[0.813rem] font-thin leading-6 text-trhp-white hover:text-trhp-teal"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <Link
                  href="/get-started/"
                  className="text-lg leading-6 mt-6 text-white font-josefin underline font-normal block"
                >
                  Prospective Adoptive Parents
                </Link>
                <Link
                  href="/pastors-and-leaders/"
                  className="text-lg leading-6 mt-6 text-white font-josefin underline font-normal block"
                >
                  Pastors + Ministry Leaders
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-10 xl:mt-0 text-center text-white normal-case text-base px-8">
            <h3 className="text-trhp-white font-thin">Follow Us</h3>
            <div className="flex items-center w-full justify-center py-3">
              {navigation.social.map(item => (
                <a
                  key={`social-${item.name}`}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-trhp-teal mx-1 border-trhp-gray shadow-inner bg-trhp-gray p-1"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <h3 className="text-trhp-white font-thin  leading-6">
              Email: info@thereelhopeproject.org
            </h3>

            <form
              className="mt-6 sm:max-w-md"
              method="post"
              onSubmit={subscribe}
              id="subscribe-to-newsletter"
            >
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                autoComplete="name"
                required
                className="w-full min-w-0 appearance-none py-3.5 border-0 bg-white px-3 py-1.5 text-base text-trhp-gray shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-64 sm:text-sm sm:leading-6 xl:w-full mb-5"
                placeholder="Name"
                onChange={e => setName(e.target.value)}
              />
              <input
                type="text"
                name="title"
                id="title"
                className="hidden"
                onChange={e => setHoney(e.target.value)}
              />
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                autoComplete="email"
                required
                className="w-full min-w-0 appearance-none py-3.5 border-0 bg-white px-3 py-1.5 text-base text-trhp-gray shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                placeholder="Email"
                onChange={e => setEmail(e.target.value)}
              />
              <div className="mt-4 sm:mt-0">
                <button
                  type="submit"
                  className="group mt-5
                  block
                  w-full
                  text-center
                  font-normal
                  border
                  text-xl
                  uppercase
                  px-4
                  py-2
                border-trhp-teal
                  bg-transparent
                hover:bg-trhp-teal
                hover:text-white
                  hover:pr-5
                text-trhp-teal
                transition-all
                ease-in delay-100"
                  onClick={subscribe}
                >
                  Subscribe
                  <span className="opacity-0 group-hover:opacity-100 w-1 pl-1 transition-all ease-in delay-100 text-base">
                    <TfiAngleRight
                      className="inline"
                      style={{ marginTop: '-5px' }}
                    />
                  </span>
                </button>
                {subscribeMsg && (
                  <p className="text-white text-lg mt-2">{subscribeMsg}</p>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="mt-16 pt-8 sm:mt-20 lg:mt-24 text-center">
          <Image
            src={Logo}
            className="inline pb-5"
            alt="The Reel Hope Project"
            width="216"
            height="62"
          />
          <p className="mt-8 text-lg leading-5 text-white md:order-1 md:mt-0 font-thin normal-case">
            The Reel Hope Project is a Minnesota non-profit 501c3. Copyright
            &copy;{year} | All Rights Reserved <br />
            EIN: 81-4909376
          </p>
        </div>
      </div>
      <Version />
    </footer>
  );
}
