import { LoadingSpinner } from 'components/LoadingSpinner';
import { TfiAngleRight } from 'react-icons/tfi';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';

interface FixedArrowButtonProps {
  className?: string;
  type?: 'button' | 'submit';
  showLoader?: boolean;
  styletype: 'primary' | 'secondary' | 'tertiary';
  hideArrow?: boolean;
  label: string;
  onClick?: () => void;
  bgOverrideColor?: string;
}
interface StyledButtonProps {
  styletype: 'primary' | 'secondary' | 'tertiary';
  bgOverrideColor?: string;
}

const BaseArrow = styled.span`
  color: #fff;
  left: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8em;
`;

const Arrow = tw(BaseArrow)`
  opacity-0
  -ml-[1em]
  group-hover:opacity-100
  group-hover:ml-1
  transition-all
  ease-in-out
  delay-100
  duration-200
`;
const StyledButton = tw.button`${(props: StyledButtonProps) =>
  props.styletype === 'primary'
    ? 'text-white border-trhp-teal bg-trhp-teal group'
    : props.styletype === 'secondary'
    ? 'border-trhp-teal bg-transparent hover:bg-trhp-teal hover:text-white text-trhp-teal'
    : 'shadow-sm hover:bg-gray-50'}
  relative text-base px-9 py-1 inline-flex text-center items-center justify-center focus:outline-none focus:ring-offset-2 font-medium focus:ring-2 border focus:ring-trhp-teal transition-all ease-in delay-100`;

export const FixedArrowButton = (props: FixedArrowButtonProps) => {
  const {
    styletype,
    label,
    type,
    showLoader,
    className,
    hideArrow,
    onClick,
    bgOverrideColor
  } = props;

  return (
    <StyledButton
      type={type ? type : 'button'}
      styletype={styletype}
      onClick={onClick}
      className={className}
      style={{
        ...(bgOverrideColor && {
          backgroundColor: bgOverrideColor,
          borderColor: bgOverrideColor
        })
      }}
    >
      {!showLoader ? (
        <>
          {label}
          {!hideArrow && (
            <Arrow>
              <TfiAngleRight />
            </Arrow>
          )}
        </>
      ) : (
        <LoadingSpinner variant={styletype} />
      )}
    </StyledButton>
  );
};
