import packageJson from '../../package.json';

export const Version = () => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '0',
        left: '5px',
        zIndex: '999',
        fontSize: '10px',
        color: 'white'
      }}
    >
      <small>{`v${packageJson.version}`}</small>
    </div>
  );
};
