import { alertService } from '../utils/alerts';
import Router from 'next/router';

class ResponseError extends Error {
  public response: any;
  public data: any;
}

export interface FetchRequest {
  id: number;
  url: string;
  controller?: AbortController;
}
interface AddFetchRequest {
  url: string;
  controller?: AbortController;
}

const generateId = () => {
  return new Date().getTime();
};

const isSsr = typeof window === 'undefined';

let requests: FetchRequest[] = [];

export const addRequest = (request: AddFetchRequest) => {
  const id = generateId();

  const newRequests = [...requests, { ...request, id }];
  requests = newRequests;
  return id;
};

export const removeRequest = (requestId: number) => {
  const newRequests = [...requests].filter(r => r.id !== requestId);
  requests = newRequests;
};

export const cancelAllRequests = () => {
  if (!requests || !requests.length) return;
  requests.forEach(r => {
    if (r.controller && !r.controller.signal.aborted) r.controller.abort();
  });

  requests = [];
};

export const getExistingRequest = (url: string): FetchRequest | undefined => {
  if (!requests || !requests.length) return;
  const request = [...requests].find(r => r.url === url);
  return request;
};

export default async function fetchJson(
  path: string,
  args: any,
  modelName: string = '',
  redirectOnCreate = true
) {
  const alertOptions = {
    id: 'global-alerts',
    autoClose: true,
    keepAfterRouteChange: false
  };
  const abortController = !isSsr ? new window.AbortController() : undefined;
  try {
    const requestId = addRequest({ url: path, controller: abortController });
    const response = await fetch(path, {
      ...args,
      signal: abortController?.signal
    });
    removeRequest(requestId);
    if (response.status === 204) {
      // for 204, there is no content so just return
      if (modelName && args.body) {
        alertService.success(`${modelName} Saved`, alertOptions);
      } else if (modelName && args.method === 'DELETE') {
        alertService.success(`${modelName} Removed`, alertOptions);
      }
      return true;
    }
    if (response.status === 201) {
      if (modelName) {
        alertService.success(`New ${modelName} created`, alertOptions);
      }
      const url = response.headers.get('Location');
      if (url && redirectOnCreate) return Router.push(url);
      if (url && !redirectOnCreate) return url.split('/').pop();
    }

    if (response.status === 500 || response.status === 404) {
      // alertService.error('', alertOptions);
      return false;
    }
    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = await response.json();
    if (response.ok) {
      return data;
    }

    if (data && data.errors) {
      const dataError = new ResponseError(data.errors[0]);
      dataError.response = response;
      dataError.data = data;
      throw dataError;
    }

    const error = new ResponseError(response.statusText);
    error.response = response;
    error.data = data;
    throw error;
  } catch (error: any) {
    if (!error.data) {
      error.data = { message: error.message };
    }
    if (error.message === 'The user aborted a request.') return;
    alertService.error(error.message, alertOptions);
    return false;
    // throw error;
  }
}
