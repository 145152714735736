import fetchJson from './fetchJson';

export async function FetchService<ResponseType, RequestType = void>(
  method: string,
  url: string,
  request?: RequestType
): Promise<ResponseType> {
  let fetchUrl = url;
  if (request && method === 'GET') {
    fetchUrl = `${url}?${new URLSearchParams({ ...request })}`;
  }
  const res = await fetchJson(fetchUrl, {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    ...(request && method !== 'GET' ? { body: JSON.stringify(request) } : {})
  });
  return res;
}
