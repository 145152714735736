import {
  CreateSubmissionProps,
  SubmissionCreatedResponse
} from 'modules/submissions/use-cases/CreateSubmission/types';
import { FetchService } from './FetchService';
import {
  ListSubmissionsRequest,
  ListSubmissionsResponse
} from 'modules/submissions/use-cases/PaginateSubmissions/types';
import { SubmissionView } from 'modules/submissions/domain/types';

export const getSubmissionById = (url: string) =>
  FetchService<SubmissionView>('GET', url);

export const paginateSubmissions = (request: ListSubmissionsRequest) =>
  FetchService<ListSubmissionsResponse, ListSubmissionsRequest>(
    'GET',
    '/api/submissions/',
    request
  );
export const createSubmission = (request: CreateSubmissionProps) =>
  FetchService<SubmissionCreatedResponse, CreateSubmissionProps>(
    'POST',
    '/api/submissions/',
    request
  );

export const deleteCompetition = (id: string) =>
  FetchService<boolean, void>('DELETE', `/api/submissions/${id}/`);
