import {
  Bars3Icon,
  BellIcon,
  UserIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { FixedArrowButton } from 'components/button/FixedArrowButton';
import { Fragment, useEffect, useState } from 'react';
import { NavProps } from './types';
import { TfiAngleDown } from 'react-icons/tfi';
import { classNames } from 'utils/string';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import tw from 'tailwind-styled-components';
interface TopNavProps extends NavProps {
  showNotifications?: boolean;
  newNotificationsCount?: number;
  center?: boolean;
  giveLink: string;
}

const Navbar = (props: TopNavProps) => {
  const {
    navItems,
    logo,
    user,
    menuItems,
    newNotificationsCount,
    darkMode,
    showNotifications,
    center,
    giveLink
  } = props;

  const router = useRouter();
  const [showGiveBtn, setShowGiveBtn] = useState(false); //defaulting to false since give is the only page for now
  const [openItem, setOpenItem] = useState<string | null>(null);
  const [openSubItem, setOpenSubItem] = useState<string | null>(null);
  useEffect(() => {
    if (router.asPath.includes('/give')) {
      setShowGiveBtn(false);
    } else {
      setShowGiveBtn(true);
    }
  }, [router.asPath]);

  const navContainerStyles = darkMode
    ? 'bg-gray-800 top-0 sticky z-10'
    : 'bg-white top-0 sticky z-10';
  const navButtonStyles = darkMode
    ? 'inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
    : 'inline-flex items-center justify-center rounded-md p-2 text-trhp-gray hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:white';
  const navCurrentStyles = darkMode
    ? 'border-b-4 border-white text-white uppercase'
    : 'border-b-4 border-ros-green text-white uppercase';
  const navStyles = darkMode
    ? 'text-gray-300 hover:bg-gray-700 hover:text-trhp-lt-gray font-normal uppercase'
    : 'text-trhp-gray border-b-4 border-transparent hover:border-gray-100 hover:text-gray-100 font-medium uppercase text-sm';
  const notificationIconStyles = darkMode
    ? 'rounded-full bg-gray-800 p-1 text-gray-400 hover:text-trhp-gray focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
    : 'flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ros-blue focus:ring-offset-2';
  const mobileCurrentNavStyles = darkMode
    ? 'bg-gray-900 text-trhp-gray uppercase'
    : 'bg-gray-100 text-gray-900 uppercase';
  const mobileNavStyles = darkMode
    ? 'text-trhp-gray hover:bg-gray-700 hover:text-trhp-gray uppercase'
    : 'text-trhp-gray hover:bg-gray-50 hover:text-gray-900 uppercase';

  const NavWrapper = tw.div`
  ${(props: { alignnav?: 'left' | 'center' }) =>
    props.alignnav === 'center' ? 'w-full' : ''}
    hidden
    lg:ml-6 
    lg:block
    my-8
  `;
  const NavItems = tw.div`
  ${(props: { alignnav?: 'left' | 'center' }) =>
    props.alignnav === 'center' ? 'justify-center' : ''}
    flex
    space-x-2
    gap-5
    md:gap-9
    h-full
    items-center
  `;

  return (
    <Disclosure as="nav" className={navContainerStyles}>
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-6 lg:px-8 py-6 lg:py-0">
            <div className="relative flex h-full items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center justify-end lg:hidden w-full">
                {/* Mobile menu button*/}
                <Disclosure.Button className={navButtonStyles}>
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              {logo && (
                <div className="flex flex-shrink-0 items-center lg:hidden">
                  <Link href="/" className="relative z-50">
                    <Image
                      className="h-12 w-auto"
                      src={logo}
                      alt="Company Logo"
                      width={200}
                      height={200}
                    />
                  </Link>
                </div>
              )}
              <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
                {logo && (
                  <div className="flex flex-shrink-0 items-center">
                    {showGiveBtn && (
                      <FixedArrowButton
                        styletype="primary"
                        label="Give"
                        onClick={() => router.push(giveLink)}
                        className="lg:hidden uppercase"
                      />
                    )}
                    <Link href="/">
                      <Image
                        className="hidden h-30 w-auto lg:block py-2.5"
                        src={logo}
                        alt="Company Logo"
                        width={200}
                        height={200}
                      />
                    </Link>
                  </div>
                )}
                <NavWrapper alignnav={center ? 'center' : 'left'}>
                  <NavItems alignnav={center ? 'center' : 'left'}>
                    {navItems.map(item => {
                      return (
                        <Fragment key={item.name}>
                          {item.children ? (
                            <div
                              onMouseEnter={() => setOpenItem(item.name)}
                              onMouseLeave={() => {
                                setOpenItem(null);
                                setOpenSubItem(null);
                              }}
                            >
                              <a
                                className="cursor-pointer text-trhp-gray hover:text-trhp-gray-100 text-sm uppercase"
                                href={item.path}
                              >
                                {item.name}{' '}
                                <TfiAngleDown className="inline pl-1 text-xs" />
                              </a>
                              <div
                                className={`${
                                  openItem === item.name
                                    ? 'opacity-100'
                                    : 'opacity-0'
                                } dropdown-menu absolute transition-opacity ease-out duration-500 h-auto z-10`}
                              >
                                <ul
                                  className={`${
                                    openItem === item.name ? 'block' : 'hidden'
                                  } relative top-0 w-60 px-5 bg-white shadow py-5 border-t-[3px] border-trhp-teal`}
                                >
                                  {item.children.map(child => (
                                    <>
                                      {child.children ? (
                                        <Fragment key={child.subItemName}>
                                          <li
                                            className="py-2.5 px-5 hover:bg-slate-50 relative"
                                            id={child.subKey}
                                            onMouseEnter={() =>
                                              setOpenSubItem(
                                                child.subKey ?? null
                                              )
                                            }
                                            onMouseLeave={() =>
                                              setOpenSubItem(null)
                                            }
                                          >
                                            <a className="cursor-pointer font-medium text-trhp-gray hover:text-trhp-gray-100 text-sm uppercase">
                                              {child.subItemName}{' '}
                                              <TfiAngleDown className="inline pl-1 text-xs float-right" />
                                            </a>
                                            <div
                                              className={`${
                                                openSubItem === child.subKey
                                                  ? 'opacity-100'
                                                  : 'opacity-0'
                                              } left-[200px] top-0 dropdown-menu absolute transition-opacity ease-out duration-500 h-auto z-10`}
                                            >
                                              <ul
                                                className={`ml-5 w-60 px-5 bg-white shadow py-5 border-t-[3px] border-trhp-teal ${
                                                  openSubItem === child.subKey
                                                    ? 'block'
                                                    : 'hidden'
                                                }`}
                                              >
                                                {child.children.map(
                                                  subChild => {
                                                    return (
                                                      <li
                                                        key={
                                                          subChild.subItemName
                                                        }
                                                        className="py-2.5 px-5 hover:bg-slate-50"
                                                      >
                                                        <Link
                                                          {...(subChild.as && {
                                                            as: subChild.as
                                                          })}
                                                          href={subChild.path}
                                                          className="block text-sm uppercase cursor-pointer font-medium text-trhp-gray"
                                                        >
                                                          {subChild.subItemName}
                                                        </Link>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </li>
                                        </Fragment>
                                      ) : (
                                        <li
                                          key={child.subItemName}
                                          className="py-2.5 px-5 hover:bg-slate-50"
                                        >
                                          <Link
                                            {...(child.as && {
                                              as: child.as
                                            })}
                                            href={child.path}
                                            className="block text-sm uppercase cursor-pointer font-medium text-trhp-gray"
                                          >
                                            {child.subItemName}
                                          </Link>
                                        </li>
                                      )}
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <Link
                              key={item.name}
                              href={item.path}
                              {...(item.as && { as: item.as })}
                              className={classNames(
                                item.current ? navCurrentStyles : navStyles,
                                'px-1 lg:px-3 py-4 text-lg font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-ros-blue focus:ring-offset-2'
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Fragment>
                      );
                    })}
                  </NavItems>
                </NavWrapper>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-24 sm:pr-0">
                {showGiveBtn && (
                  <FixedArrowButton
                    styletype="primary"
                    label="Give"
                    onClick={() => router.push(giveLink)}
                    className="hidden lg:block uppercase"
                  />
                )}
                {showNotifications && (
                  <Link
                    href="/account/notifications"
                    className={classNames(
                      notificationIconStyles,
                      'relative flex text-xs items-center focus:outline-none focus:ring-2 focus:ring-ros-blue focus:ring-offset-2'
                    )}
                  >
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                    <span className="absolute left-4 top-0 rounded-full bg-red-600 w-4 h-4 top right p-0 pt-0.5 m-0 text-white font-mono text-xs leading-tight text-center">
                      {newNotificationsCount}
                    </span>
                    <span className="pl-3 hidden lg:block">Notifications</span>
                  </Link>
                )}

                {menuItems && (
                  <Menu as="div" className="relative ml-3">
                    <div>
                      {user ? (
                        <Menu.Button className="flex rounded-full pr-2 text-sm focus:outline-none focus:ring-2 focus:ring-ros-blue focus:ring-offset-2 items-center">
                          <span className="sr-only">Open user menu</span>
                          {user?.src ? (
                            <Image
                              className="h-8 w-8 rounded-full bg-gray-800"
                              src={user.src}
                              alt={user.name}
                              width={100}
                              height={100}
                            />
                          ) : (
                            <UserIcon
                              className={classNames(
                                notificationIconStyles,
                                'h-8 w-8'
                              )}
                            />
                          )}
                          <span className="pl-1 hidden lg:block text-xs text-gray-400">
                            {user?.name}
                          </span>
                        </Menu.Button>
                      ) : null}
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {menuItems?.map(
                          (
                            item: { name: string; path: string },
                            ind: number
                          ) => (
                            <Menu.Item key={ind}>
                              {({ active }) => (
                                <Link
                                  href={item.path}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}
                            </Menu.Item>
                          )
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div>
            </div>
          </div>
          <Transition
            as={Fragment}
            enterFrom="transform scale-5 ease-in-out opacity-0 max-h-0 -translate-y-12 duration-500"
            enterTo="transform scale-100 opacity-100 max-h-[1000px] translate-y-0"
            leaveFrom="transform scale-100 opacity-100 max-h-[1000px] translate-y-0"
            leaveTo="transform scale-5 opacity-0 max-h-0 -translate-y-12"
          >
            <Disclosure.Panel className="lg:hidden border-t-[3px] border-trhp-teal transition-all absolute z-50 bg-white w-full sm:w-2/4 sm:right-0">
              {({ close }) => (
                <div className="space-y-1 px-2 pt-2 pb-3">
                  {navItems.map(item => (
                    <Fragment key={item.name}>
                      {item.children ? (
                        <Disclosure as="ul" className="">
                          <Disclosure.Button
                            className={classNames(
                              item.current
                                ? mobileCurrentNavStyles
                                : mobileNavStyles,
                              'flex text-left w-full px-3 py-2 rounded-md text-base font-medium justify-between'
                            )}
                          >
                            {item.name}
                            <TfiAngleDown className="inline text-xs font-medium" />
                          </Disclosure.Button>
                          <Transition
                            as={Fragment}
                            enterFrom="transform scale-5 opacity-0 max-h-0 -translate-y-12"
                            enterTo="transform scale-100 opacity-100 max-h-[1000px] translate-y-0"
                            leaveFrom="transform scale-100 opacity-100 max-h-[1000px] translate-y-0"
                            leaveTo="transform scale-5 opacity-0 max-h-0 -translate-y-12"
                          >
                            <Disclosure.Panel className="transition-all duration-400">
                              {item.children.map((child, ind) => {
                                if (child.children) {
                                  return (
                                    <Disclosure
                                      as="ul"
                                      className=""
                                      key={`${ind}-${child.subItemName}`}
                                    >
                                      <Disclosure.Button
                                        className={classNames(
                                          'pl-[2.5rem]',
                                          'flex text-left w-full px-3 py-2 rounded-md text-base justify-between'
                                        )}
                                      >
                                        {child.subItemName}
                                        <TfiAngleDown className="inline text-xs font-medium" />
                                      </Disclosure.Button>
                                      <Transition
                                        as={Fragment}
                                        enterFrom="transform scale-5 opacity-0 max-h-0 -translate-y-12"
                                        enterTo="transform scale-100 opacity-100 max-h-[1000px] translate-y-0"
                                        leaveFrom="transform scale-100 opacity-100 max-h-[1000px] translate-y-0"
                                        leaveTo="transform scale-5 opacity-0 max-h-0 -translate-y-12"
                                      >
                                        <Disclosure.Panel className="transition-all duration-400">
                                          {child.children.map(subChild => {
                                            return (
                                              <li
                                                key={subChild.subItemName}
                                                className="block pl-[5%]"
                                              >
                                                <Disclosure.Button
                                                  as="a"
                                                  href={subChild.path}
                                                  className="py-2.5 px-12 block"
                                                >
                                                  {subChild.subItemName}
                                                </Disclosure.Button>
                                              </li>
                                            );
                                          })}
                                        </Disclosure.Panel>
                                      </Transition>
                                    </Disclosure>
                                  );
                                } else {
                                  return (
                                    <li
                                      key={child.subItemName}
                                      className="block pl-[5%]"
                                    >
                                      <Link
                                        {...(child.as && { as: child.as })}
                                        href={child.path}
                                        className="py-2.5 px-6 block"
                                        onClick={() => close()}
                                      >
                                        {child.subItemName}
                                      </Link>
                                    </li>
                                  );
                                }
                              })}
                            </Disclosure.Panel>
                          </Transition>
                        </Disclosure>
                      ) : (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.path}
                          className={classNames(
                            item.current
                              ? mobileCurrentNavStyles
                              : mobileNavStyles,
                            'block w-full text-left px-3 py-2 rounded-md text-base font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      )}
                    </Fragment>
                  ))}
                </div>
              )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
